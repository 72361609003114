<template>
  <CRow
      v-if="can('provider_user')"
      class="w-100 justify-content-center">
    <CCol col="12" sm="12" md="12" xl="12" lg="12">
      <CCard>
        <CCardHeader>
          <h2>Архів</h2>
        </CCardHeader>
        <CCardBody>
          <CRow v-if="is_loader" class="justify-content-center">
            <CSpinner color="primary" style="width:4rem;height:4rem;"/>
          </CRow>
          <CDataTable
              v-if="offers !== null"
              hover
              striped
              :items="offers"
              :fields="fieldsArchive"
              :items-per-page="perPage"
              :items-per-page-select="{label:'Кількість на сторінці: ', values:[5,10,20,30,50]}"
              :noItemsView="{ noResults: 'За результатами пошуку нічого не знайдено', noItems: 'no items available' }"
              index-column
              @pagination-change="getPerPage"
              @update:sorter-value="getColumnSorter"
              clickable-rows
              sorter
              @row-clicked="showDetails"
          >
            <!--                        :sorter-value="{column:'created_at', desc:true}"-->

            <template #branch_a="data">
                <td>
                  <span class="font-weight-bold">{{ data.item.merchant || data.item.branch_a }}</span>
                  {{ data.item.branch_a_address }}
                </td>
              </template>
              <template #merchant>
                <td></td>
              </template>
            <template #created_at="data">
              <td>
                {{ timeConvertTender(data.item.created_at) }}
              </td>
            </template>
            <template #order_status="data">
              <td>
                <CBadge class="p-1" :color="getBadgeStatus(data.item.order_status)">
                  {{ statusTender(data.item.order_status) }}
                </CBadge>
              </td>
            </template>
            <template #status="data">
              <td>
                <CBadge class="p-1" :color="getBadgeStatusOrder(data.item.status)">
                  {{ statusOrders(data.item.status) }}
                </CBadge>
              </td>
            </template>
            <template #download_speed="data">
              <td>
                {{ data.item.download_speed }}/{{ data.item.upload_speed }}
              </td>
            </template>
          </CDataTable>
          <span v-else>
                         <h4>Ви ще не брали участі в жодному тендері! :(</h4>
                    </span>
          <CPagination
              v-if="countPage>=1"
              align="center"
              :pages="Math.ceil(countPage)"
              :active-page.sync="activePage"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
        color="primary"
        title="Деталі тендера"
        :show.sync="showDetailsModal"
        size="lg"
        addContentClasses="srl"
    >
      <template #body-wrapper>
        <table class="table">
          <tbody>
          <tr>
            <th>
              Бранч/Адреса А
            </th>
            <td>
              <span class="font-weight-bold">{{ details_offers.branch_a }}</span> {{ details_offers.branch_a_address }}
            </td>
          </tr>
          <tr>
            <th>
              Призначення
            </th>
            <td>
              {{ details_offers.channel_purpose }}
            </td>
          </tr>
          <tr>
            <th>
              Тип каналу
            </th>
            <td>
              {{ details_offers.connect_type }}
            </td>
          </tr>

          <tr v-if="details_offers.connect_type !== 'Інтернет'">
            <th>
              Бранч/Адреса Б
            </th>
            <td>
              <span class="font-weight-bold">{{ details_offers.branch_a }}</span> {{ details_offers.branch_b_address }}
            </td>
          </tr>
          <tr>
            <th>
              Тип канала
            </th>
            <td>
              {{ details_offers.consumer_type }}
            </td>
          </tr>
          <tr>
            <th>
              Швидкість, Мбіт/с
            </th>
            <td>
              {{ details_offers.channel_speed }}
            </td>
          </tr>
          <tr>
            <th>
              Початок тендера
            </th>
            <td>
              {{ timeConvert(details_offers.created_at) }}
            </td>
          </tr>
          </tbody>
        </table>
        <h5 class="ml-3">Моя пропозиція</h5>
        <table class="table">
          <tbody>
          <tr>
            <th>Вартість підключення, грн з ПДВ</th>
            <td>{{ details_offers.connection_cost }}</td>
          </tr>
          <tr>
            <th>Щомісячний платіж, грн з ПДВ</th>
            <td>{{ details_offers.monthly_payment }}</td>
          </tr>
          <tr>
            <th>Швидкість, Мбіт/с (Вх/Вих)</th>
            <td>{{ details_offers.download_speed }}/{{ details_offers.upload_speed }}</td>
          </tr>
          <tr>
            <th>Тип каналу</th>
            <td>{{ details_offers.type_channel }}</td>
          </tr>
          <tr>
            <th>Фізика</th>
            <td>{{ $store.getters.getPhysicsByID(details_offers.physics_id) }}</td>
          </tr>
          <tr>
            <th>Інтерфейс</th>
            <td>{{ $store.getters.getInterfaceByID(details_offers.iface_id) }}</td>
          </tr>
          <tr>
            <th>Протокол</th>
            <td>{{ $store.getters.getProtocolByID(details_offers.protocol_id) }}</td>
          </tr>
          <tr>
            <th>Потрібно обладнання?</th>
            <td>{{ details_offers.is_equipment ? "Так" : "Ні" }}</td>
          </tr>
          <tr v-if="details_offers.is_equipment">
            <th>Впишіть обладнання</th>
            <td>{{ details_offers.extra }}</td>
          </tr>
          <tr v-if="details_offers.is_equipment">
            <th>Чиє обладнання?</th>
            <td>{{ details_offers.whose_equipment }}</td>
          </tr>
          <tr v-if="details_offers.is_equipment">
            <th>Умови передачі обладнання?</th>
            <td>{{ details_offers.equipment_transfer }}</td>
          </tr>
          <tr>
            <th> Необхідний час для організації каналу</th>
            <td>{{ details_offers.days_to_connect }}</td>
          </tr>
          <tr>
            <th>Примітка</th>
            <td>{{ details_offers.note }}</td>
          </tr>
          </tbody>
        </table>
      </template>
      <template #footer>
        <CButton
            @click="showDetailsModal = false"
            color="primary">
          Закрити
        </CButton>
      </template>
    </CModal>
  </CRow>

</template>

<script>
import axios from "axios"
import statusTenderAndPropositionMixin from '@/mixins/statusTenderAndPropositionMixin'
import timeConvertMixin from '@/mixins/timeConvertMixin'

export default {
  mixins: [statusTenderAndPropositionMixin, timeConvertMixin],
  name: 'Archive',
  data: () => {
    return {
      columnSorter: {
        asc: false,
        column: 'created_at',
      },
      newPerPage: 20,
      activePage: 1,
      countPage: 0,
      is_loader: false,
      showDetailsModal: false,
      fieldsArchive: [
        {key: 'created_at', label: 'Старт тендеру'},
        {key: 'branch_a', label: 'Бранч/Адреса'},
        {key: 'type_channel', label: "Тип канала"},
        {key: 'download_speed', label: 'Швидкість, Мбіт/с (Вх/Вих)'},
        {key: 'connection_cost', label: 'Підключення, грн з ПДВ'},
        {key: 'monthly_payment', label: 'Абонплата, грн з ПДВ'},
        {
          key: 'order_status',
          label: 'Статус тендера',
        },
        {key: 'status', label: 'Статус пропозиції'},
      ],

      perPage: 20,
      offers: [],
      details_offers: [],
    }
  },
  filterProps: {
    label: "Фільтр",
    placeholder: ""
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  methods: {
    getPerPage(e) {
      this.newPerPage = e;
    },
    rowClicked(item, index) {
      const userLink = this.userLink(index + 1)
      this.$router.push({path: userLink})
    },
    getAccountOffers(limit, page, columnSorter) {
      let self = this;
      let sort = null;
      self.is_loader = true;
      sort = columnSorter.asc
      if (sort) {
        sort = 'asc'
      } else {
        sort = 'desc'
      }
      axios.get(`/api/account/offers?limit=${limit}&sort=${sort}&order_by=${columnSorter.column}&offset=${limit * (page - 1)}`)
          .then(function (response) {
            self.offers = response.data ?? [];
            self.is_loader = false;
          }).catch(function () {
        self.is_loader = true;
      });
    },

    showDetails(item) {
      let self = this;
      // axios.get('/api/account/offers/'+item.id)
      axios.get('/api/account/offers/' + item.id + '/history')
          .then(function (response) {
            self.details_offers = response.data

            // self.physics.forEach((item) => {
            //   if (item.id === self.details_offers.physics_id) {
            //     self.details_offers.physics_id = item.name;
            //   }
            // });
            // self.interfaces.forEach((item) => {
            //   if (item.id === self.details_offers.iface_id) {
            //     self.details_offers.iface_id = item.name;
            //   }
            // });
            // self.protocol.forEach((item) => {
            //   if (item.id === self.details_offers.protocol_id) {
            //     self.details_offers.protocol_id = item.name;
            //   }
            // });
            self.showDetailsModal = true;
          })
    },
    getHistoryPagination() {
      let self = this;
      axios.get(`/api/account/offers/count`)
          .then(function (response) {
            self.countPage = response.data / self.newPerPage;
          })
          .catch(function () {
            self.countPage = 0
          })
    },
    isStart(one, two, tree) {
      this.is_loader = true;
      this.getAccountOffers(one, two, tree);
      this.getHistoryPagination();
    },
    getColumnSorter(e) {
      //column sorter
      this.columnSorter = e;

    },
    funcShowHistory() {
      this.$store.dispatch('GET_ORGANIZATION');
    }
  },
  created() {
    if (!this.$store.getters.getPhysicsLength) this.$store.dispatch('GET_Physics');
    if (!this.$store.getters.getInterfaceLength) this.$store.dispatch('GET_Interface');
    if (!this.$store.getters.getProtocolLength) this.$store.dispatch('GET_Protocol');
    this.funcShowHistory();
    this.is_loader = true;
    // this.getAccountOffers();
    this.isStart(this.newPerPage, this.activePage, this.columnSorter)
  },
  computed: {
    tableColumnSorter() {
      return {
        columnSorter: {
          column: this.columnSorter.column,
          asc: this.columnSorter.asc
        }
      }
    }
  },
  watch: {
    '$store.state.login_info.ready': function () {
      return this.funcShowHistory();
    },
    'activePage': function () {
      return this.isStart(this.newPerPage, this.activePage, this.columnSorter);
    },
    'newPerPage': function () {
      this.activePage = 1;
      return this.isStart(this.newPerPage, this.activePage, this.columnSorter);
    },
    tableColumnSorter() {
      this.activePage = 1;
      return this.isStart(this.newPerPage, this.activePage, this.columnSorter);
    },
  }
}

</script>
